<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Australia from '@/components/Map/Australia.vue'

// Vuex
const store = useStore()
const router = useRouter()

// State list retrieval from api
const stateList = ref([])
async function getStates() {
  await store
    .dispatch('getStates')
    .then((response) => {
      stateList.value = response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

onMounted(async () => {
  await getStates()
})

function selectState(state) {
  // Ensure both has their own value to avoid unable to find index
  if (state && stateList.value.length) {
    // Temporary array index is the ID
    let foundStateId = stateList.value.findIndex((s) => s.code === state)

    store.state.stateID = foundStateId
    router.push({ name: 'service-requirement-selection' })
  }
}
</script>

<template>
  <div class="d-flex flex-column align-items-center">
    <p class="my-5 pb-5">
      Please select the most appropriate location/jurisdiction
    </p>
    <Australia @click="selectState" />
  </div>
</template>

<style lang="scss"></style>
